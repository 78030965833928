<template>
  <div>
    <a-card class="container" :loading="loading">
     
      <a-page-header>
        <a-radio-group default-value="1" button-style="solid" class="float-right margin-right-10 margin-left-10"
          @change="on_view_change" v-model="cell_query.group_type">
          <a-radio-button value="cell">栏舍视图</a-radio-button>
          <!-- <a-radio-button value="tag">分组视图</a-radio-button> -->
          <a-radio-button value="selectRecord">选配记录</a-radio-button>
        </a-radio-group>
        <a-input v-model="query.name" placeholder="请输入牧场名称" style="width:200px" class="float-left"></a-input>
        <a-button @click="refresh_list" type="primary" size="small" class="margin-left-10 float-left" shape="round">查询</a-button>
        <a-button class="float-left margin-left-10" type="primary" size="small" @click="edit_farm('add', null)" shape="round">添加牧场</a-button>
      </a-page-header>
     
      <a-row :gutter="20">
        <a-col :span="4">
          <h3 class="bold margin-left-20">牧场列表</h3>
          <div>
            <div :class="farm.id == item.id ? 'farm-btn farm-btn-selected' : 'farm-btn'" v-for="item, index in list" :key="index"
                 @click="open_farm(item)">
              {{farm.name}}
            </div>
          </div>
        </a-col>
        <a-col :span="20">
          
          <div class="flex">
            <div style="width: 50%;" class="font-20 bold">{{ farm.name }}</div>
            <div v-if="cell_query.group_type == 'cell'">
              <a-button type="primary" size="small" @click="edit_farm('edit', farm)" shape="round">编辑</a-button>
              <a-button type="primary" size="small" @click="edit_farm_cell('add', farm, null, null)" class="margin-left-10" shape="round">新增群</a-button>
            </div>
            <div v-if="cell_query.group_type == 'selectRecord'">
              <a-button type="primary" size="small" @click="edit_select_record('add', farm, null)" class="margin-left-10" shape="round">新增选配记录</a-button>
            </div>
          </div>
          <a-divider></a-divider>
          <a-row class="margin-top-20">
            <a-col :span="24" class="flex-center">
              <a-spin v-if="loading"></a-spin>
            </a-col>
          </a-row>
          <a-empty description="暂无分群" v-if="(!farm_groups || !farm_groups.l1_farm_cells ||farm_groups.l1_farm_cells.length == 0 ) && cell_query.group_type != 'selectRecord'"></a-empty>
          <a-card :title="l1_cell.name" v-for="l1_cell, l1_index in farm_groups.l1_farm_cells" :key="l1_index" :class="l1_index > 0 ? 'margin-top-10' : ''"
            v-if="cell_query.group_type == 'cell'" :headStyle="headStyle">
            <template slot="extra">
              <a-button type="danger" @click="delete_farm_cell('edit', farm, l1_cell)" class="margin-left-  10 " shape="round">删除</a-button>
              <a-button type="primary" @click="edit_farm_cell('edit', farm, l1_cell)" class="margin-left-10 " shape="round">编辑</a-button>
              <a-button type="primary" @click="edit_l2_farm_cell('add', farm, null, l1_cell)" class="margin-left-10 " shape="round">新增子群</a-button>
            </template>
            <a-row :gutter="10">
              <a-empty description="暂无群舍" v-if="l1_cell.l2_farm_cells.length == 0"></a-empty>
              <a-col :span="6" v-for="l2_cell, l2_index in l1_cell.l2_farm_cells" :key="l2_index">
                <div class="cell-box":class="parseFloat(l2_index + 1) / 4 > 1 ? 'margin-top-10' : '' ">
                  <a-row>
                    <a-col :span="17" @click="open_cell(l2_cell.id)">
                      <a-row>
                        <div class="font-20">{{ l2_cell.name }}</div>
                      </a-row>
                      <a-row>
                        <span class="font-10 primary-color" >总数：</span> <span class="primary-color">{{ l2_cell.item_count  }}</span><span class="primary-color">只</span>
                      </a-row>
                    </a-col>
                    <a-col :span="7">
                      <a-button ghost size="small" type="link" icon="form" class="grey-text" @click="edit_farm_cell('edit', farm, l2_cell)">编辑</a-button>
                      <a-button ghost size="small" type="link" icon="delete" class="margin-top-5 danger-color" @click="delete_farm_cell(l2_cell.id)">删除</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
            </a-row>
          </a-card>

          <div v-if="cell_query.group_type == 'selectRecord'">
            <div class="cell-box margin-top-10"
              v-for="record, index in select_records" :key="index">
              <a-row>
                <a-col :span="18" @click="open_cell(record.id, 'selectRecord')" >
                  <a-row>
                    <div class="font-20">{{ record.name }}</div>
                  </a-row>
                  <a-row>
                    <span class="font-10 primary-color">总数：</span> <span class="primary-color bold">{{ record.item_count  }}</span><span class="primary-color">只</span>
                  </a-row>
                  <a-row>
                    <span class="font-10 primary-color">创建时间: <span>{{ record.created_at }}</span></span>
                  </a-row>
                </a-col>
                <a-col :span="6">
                  <a-button type="danger" ghost class="float-right margin-top-10" @click="delete_select_record(record.id)">删除</a-button>
                </a-col>
              </a-row>
            </div>
            <a-pagination v-model="cell_pagination.current"
              class="margin-top-10 float-right"
              :pageSize="cell_query.page_size"
              :showTotal="total => `共${total}条`" :total="cell_pagination.total"
              @change="select_records_page_change"></a-pagination>
          </div>

          <!-- <div v-if="cell_query.group_type == 'tag'">
            <a-row type="flex" :gutter="10">
              <a-col :span="6" v-for="l1_cell, l1_index in farm.l1_farm_cells" :key="l1_index">
                <div class="cell-box" :class="parseFloat(l1_index + 1) / 4 > 1 ? 'margin-top-10' : '' ">
                  <a-row>
                    <a-col :span="18" @click="open_cell(l1_cell.id, 'tag')" >
                      <a-row>
                        <div class="font-20">{{ l1_cell.name }}</div>
                      </a-row>
                      <a-row>
                        <span class="font-10 primary-color">总数：</span> <span class="primary-color">{{ l1_cell.item_count  }}</span><span class="primary-color">只</span>
                      </a-row>
                      <a-row>
                        <span class="font-10 primary-color">创建时间: <span>{{ l1_cell.created_at }}</span></span>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
            </a-row>
          </div> -->
        </a-col>
      </a-row>
      <a-empty description="暂无牧场" v-if="list.length == 0 && !loading"></a-empty>
    </a-card>

    <addOrEdit ref="addOrEdit" @finish-edit="refresh_list"></addOrEdit>
    <addOrEditCell ref="addOrEditCell" @finish-edit="refresh_list"></addOrEditCell>
    <addOrEditSelectRecord ref="addOrEditSelectRecord" @finish-edit="refresh_list"></addOrEditSelectRecord>
  </div>
</template>

<script>
import addOrEdit from './components/addOrEdit.vue'
import addOrEditCell from './components/addOrEditCell.vue'
import addOrEditSelectRecord from './components/addOrEditSelectRecord.vue';
export default ({
  components: {addOrEdit, addOrEditCell, addOrEditSelectRecord},
  data() {
    return {
      list: [],
      query: {
        name: '',
        page_no: 1,
        page_size: 20
      },
      cell_query: {
        farm_id: '',
        group_type: 'cell',
        page_no: 1,
        page_size: 20
      },
      cell_pagination: {
        current: 1,
        total_pages: 1,
        total: 0
      },
      select_records: [],
      loading: true,
      farm: {},
      farm_groups: {},
      groups: [],
      headStyle: {
        backgroundColor: '#EEF5FE ', // 自定义 header 背景色
        color: '#333', // 自定义 header 文字颜色
        "border-radius": '10px 10px 0px 0px',
        "font-weight": 'bold'
      }
    }
  },
  async created() {
    this.get_farms(this.query)
  },
  methods: {
    open_farm(farm) {
      this.farm = farm
      this.cell_query.farm_id = farm.id
      this.cell_query.page_no = 1
      this.refresh_list()
    },
    get_farms(query) {
      this.$api.get_farms(query).then(res => {
        this.list = res.data.list
        if (this.farm.id == undefined && this.list.length > 0) {
          this.farm = this.list[0]
        }
        if (this.groups.length == 0) {
          this.cell_query.farm_id = this.list[0].id
          this.refresh_list()
        }
      })
    },
    open_cell(id, group_type='cell') {
      this.$router.push({
        path: `/farms/cell/${id}?group_type=${group_type}`
      })
    },
    edit_farm(action, item) {
      this.$refs.addOrEdit.open(action, item)
    },
    refresh_list() {
      this.loading = true
      this.farm_groups = {}
      if (['cell', 'tag'].includes(this.cell_query.group_type)) {
        this.$api.get_farm_groups(this.cell_query).then(res => {
          if (res.code == 200) {
            this.farm_groups = res.data
            this.loading = false
          }
        })
      } else {
        const payload = {
          farm_id: this.cell_query.farm_id,
          page_no: this.cell_query.page_no,
          page_size: this.cell_query.page_size
        }
        this.$api.get_item_select_records(payload).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.select_records = res.data
            this.cell_pagination.current = res.page.page_no
            this.cell_pagination.total_pages = res.page.total_pages
            this.cell_pagination.total = res.page.total
          }
        })
      }
    },
    select_records_page_change(e) {
      this.cell_query.page_no = e
      this.refresh_list()
    },
    edit_farm_cell(action, farm, item, parent) {
      this.$refs.addOrEditCell.open(action, farm, item, parent?.id)
    },
    edit_l2_farm_cell(action, farm, item, l1_cell) {
      this.$refs.addOrEditCell.open(action, farm, item, l1_cell?.id)
    },
    edit_select_record(action, farm, item) {
      this.$refs.addOrEditSelectRecord.open(action, farm, item)
    },
    delete_farm_cell(id) {
      const _self = this
      this.$confirm({
        title: '确认删除该子群？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _self.$api.delete_farm_cell(id).then(res => {
            if (res.code == 200) {
              _self.$message.success('删除成功')
              _self.refresh_list()
            }
          })
        }
      })
    },
    on_view_change(val) {
      this.cell_query.group_type = val.target.value
      this.refresh_list()
    },
    on_select_farm(farm) {

    },
    delete_farm_cell(mode, farm, l1_cell) {
      const _self = this
      this.$confirm({
        title: '确认删除该子群？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _self.$api.delete_farm_cell(l1_cell.id).then(res => {
            if (res.code == 200) {
              _self.$message.success('删除成功')
            }
          })
        }
      })
    },
    delete_select_record(id) {
      const _self = this
      this.$confirm({
        title: '确认删除该记录？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _self.$api.delete_item_select_record_by_id(id).then(res => {
            if (res.code == 200) {
              _self.$message.success('删除成功')
              _self.refresh_list()
            }
          })
        }
      })
    }
  }
})
</script>

<style scoped>

.farm-btn {
  height: auto;
  min-height: 50px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  line-height: 50px;
  border-radius: 10px;
  padding-left: 20px;
  background-color: #EEF5FE;
  color: #3F598B;
  cursor: pointer;
  transition: 0.2s;
  border: #e9e9e9 solid 1px;
}
.farm-btn-selected {
  background-color: #186DF5;
  color: #ffffff;
}
.farm-btn:hover {
  height: auto;
  min-height: 50px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
  line-height: 50px;
  border-radius: 10px;
  padding-left: 20px;
  background-color: #186DF5;
  color: #ffffff;
  cursor: pointer;
  border: #3b85fc solid 1px;
}
</style>