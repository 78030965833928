
export default ({ request }) => ({
  get_item_select_records(params) {
    return request({
      url: '/v1/item_select_records',
      method: 'get',
      params: params
    })
  },
  get_item_select_record_by_id(id) {
    return request({
      url: `/v1/item_select_records/${id}`,
      method: 'get',
    })
  },
  delete_item_select_record_by_id(id) {
    return request({
      url: `/v1/item_select_records/${id}`,
      method: 'delete',
    })
  },
  post_item_select_record(data) {
    return request({
      url: '/v1/item_select_records',
      method: 'post',
      data: data
    })
  },
  put_item_select_record(id, data) {
    return request({
      url: `/v1/item_select_records/${id}`,
      method: 'put',
      data: data
    })
  },
})
