<template>
  <div>
    <a-button :type="is_dead ? 'danger' : 'primary'" size="small" ghost
    @click="to_detail_page">{{ text && text.length > 0 ? text : `id:${id}` }}</a-button>
  </div>
</template>

<script>
export default {
  name: "ItemButton",
  props: {
    text: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      default: 0
    },
    is_dead: {
      type: Number, default: 0
    }
  },
  data() {
    return {

    };
  },
  created() {},
  methods: {
    to_detail_page() {
      this.$router.push({
        path: `/breed_items/${this.id}`,
      }).catch(err => {});
    }
  }
};
</script>

<style>
</style>