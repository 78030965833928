
export default ({ request }) => ({
  get_yu_zhong_records(params) {
    return request({
      url: '/v1/yu_zhong_records/list',
      method: 'get',
      params: params
    })
  },
  post_yu_zhong_records(data) {
    return request({
      url: '/v1/yu_zhong_records/add',
      method: 'post',
      data: data
    })
  },
})
