
export default ({ request }) => ({
  user_login(params) {
    return request({
      url: '/v1/users/login',
      method: 'post',
      data: params
    })
  },
  update_user(id, params) {
    return request({
      url: `/v1/users/${id}`,
      method: 'put',
      data: params
    })
  }
})
