<template>
  <a-card class="container">
    <a-page-header :title="group_type == 'cell' ? '分组管理' : '选配记录管理'" @back="back()" >
      <template #extra>
        <a-button type="primary" class="float-right" shape="round" @click="batch_add_items">批量添加个体</a-button>
      </template>
    </a-page-header>

    <a-card class="margin-top-20">
      <a-row>
        <a-col :span="8">
          <a-statistic title="农场名称" :value="farm_cell.farm.name"></a-statistic>
        </a-col>
        <a-col :span="8">
          <a-statistic :title="group_type == 'cell' ? '农舍名称' : '选配记录名称'" :value="farm_cell.name"></a-statistic>
        </a-col>
        <a-col :span="8">
          <a-statistic title="当前个体数量" :value="farm_cell.item_count"></a-statistic>
        </a-col>
      </a-row>
    </a-card>

    <a-card :title="`${farm_cell.name} ${group_type == 'cell' ? '分群' : '选配记录'}个体列表`" class="margin-top-30" bordered
      :headStyle="headStyle">
      <item-table :page_size="10" ref="itemTable" @page-change="(page) => load_items(page)"></item-table>
    </a-card>


    <a-card title="候选匹配个体" :headStyle="headStyle" class="margin-top-30">
      <a-radio-group :default-value="1" body-style="solid" v-model="xuan_pei_query.match_all"  @change="load_xuan_pei_items()" >
        <a-radio-button :value="1">精确匹配</a-radio-button>
        <a-radio-button :value="0">模糊匹配</a-radio-button>
      </a-radio-group>
      <a-select placeholder="选择排除的亲缘代数" class="margin-left-10" style="width: 200px"
        v-model="xuan_pei_query.gen_num" @change="load_xuan_pei_items()">
        <a-select-option key="3代" value="3">3代</a-select-option>
        <a-select-option key="3代" value="4">4代</a-select-option>
        <a-select-option key="3代" value="5">5代</a-select-option>
      </a-select>
      <a-input v-model="xuan_pei_query.vid" placeholder="输入个体vid" class="margin-left-10" style="width: 200px" @blur="load_xuan_pei_items"></a-input>
      <a-switch checked-children="可用" unCheckedChildren="不可用" @change="on_state_select_change" class="margin-left-10"></a-switch>
      
        <!-- <a-button type="primary" v-for="item, index in xuan_pei_items" :key="index" @click="load_yu_zhong_results(item)">{{ item.vid }}</a-button> -->
      
      <a-table 
        :dataSource="xuan_pei_items"
        :columns="xuan_pei_columns"
        :pagination="xuan_pei_page"
        @change="(page) => load_xuan_pei_items(page)">
        <template slot="vid" slot-scope="vid, row">
          <item-button :text="vid" :id="row.id || row.rf_id || row.id" :is_dead="row.is_dead"></item-button>
        </template>
        <template slot="export_tag" slot-scope="export_tag, farm_id">
          {{ export_tag || '-' }}
        </template>
        <template slot="rf_id" slot-scope="rf_id, farm_id">
          {{ rf_id || '-' }}
        </template>
        <template slot="father" slot-scope="father, row">
          <item-button :text="row.item_father" :id="row.father_id" :is_dead="row.is_dead"></item-button>
        </template>
        <template slot="mother" slot-scope="mother, row">
          <item-button :text="row.item_mother" :id="row.mother_id" :is_dead="row.is_dead"></item-button>
        </template>
        <template slot="birth_date" slot-scope="birth_date">
          {{ birth_date || '-' }}
        </template>
        <template slot="gender" slot-scope="gender">
          {{ gender == 0 ? '母' : '公' }}
        </template>
        <template slot="operation" slot-scope="scope, row">
          <a-button type="primary" size="small" @click="open_yu_zhong_result(row)">查看</a-button>
        </template> 
      </a-table>
    </a-card>

    <a-modal :visible="show_yu_zhong_dialog" :width="1200"
      :title="`候选个体编号：${picked_yu_zhong_item.vid}`"
      @cancel="on_yu_zhong_dialog_close">
      <a-radio-group :default-value="1" body-style="solid" v-model="yu_zhong_result_query.reverse_select"  @change="onReverseSelectChange" 
        class="margin-left-10">
        <a-radio-button :value="1">显示不可匹配个体</a-radio-button>
        <a-radio-button :value="0">显示可匹配个体</a-radio-button>
      </a-radio-group>
      <a-result
        v-if="yu_zhong_pagination.total == 0 && xuan_pei_query.match_all == 1"
        status="success"
        title="组内所有个体可配种"
        sub-title=""
      >
      </a-result>
      <yuZhongResultTable
        v-show="yu_zhong_pagination.total > 0"
        ref="resultTable" @page-change="onYuZhongPageChange" :male_item="picked_male_item"></yuZhongResultTable>
    </a-modal>
    <farmCellAddItems ref="farmCellAddItems" @finish-edit="refresh_list"></farmCellAddItems>
  </a-card>
</template>

<script>
import itemTable from './../breed_items/components/itemTable.vue'
import yuZhongResultTable from '../yu_zhong/components/yuZhongResultTable.vue'
import ItemButton from '../components/ItemButton.vue';
import farmCellAddItems from './components/farmCellAddItems.vue';
import FarmCellAddItems from './components/farmCellAddItems.vue';
export default {
  components: {
    itemTable, yuZhongResultTable, ItemButton, farmCellAddItems
  },
  data() {
    return {
      farm_cell: {},
      group_type: 'cell',
      cell_id: '',
      items: [],
      xuan_pei_items: [],
      picked_male_item: {},
      show_yu_zhong_dialog: false,
      picked_yu_zhong_item: {},
      yu_zhong_result_count: 0,
      xuan_pei_page: {
        current: 1,
        total: 0,
        showTotal: total => `共 ${total} 条记录`
      },
      xuan_pei_query: {
        vid: '',
        farm_id: '',
        farm_cell_id: '',
        item_tag_id: '',
        gender: 1,
        page_no: 1,
        page_size: 10,
        match_all: 1,
        group_type: 'cell',
        gen_num: undefined,
        state: undefined
      },
      yu_zhong_result_query: {
        id: '',
        page_no: 1,
        page_size: 20,
        farm_cell_id: '',
        reverse_select: 1,
        group_type: 'cell',
        gen_num: undefined
      },
      yu_zhong_pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
        showTotal: total => `共 ${total} 条记录`
      },
      xuan_pei_columns: [
        {
          title: "Vid",
          dataIndex: "vid",
          key: "vid",
          scopedSlots: {customRender: 'vid'}
        },
        {
          title: "进口号",
          dataIndex: "export_tag",
          key: "export_tag",
          scopedSlots: {customRender: 'export_tag'}
        },
        {
          title: "RF-Id",
          dataIndex: "rf_id",
          key: "rf_id",
          scopedSlots: {customRender: 'rf_id'}
        },
        {
          title: "父亲",
          dataIndex: "father",
          key: "father",
          scopedSlots: {customRender: 'father'}
        },
        {
          title: "母亲",
          dataIndex: "mother",
          key: "mother",
          scopedSlots: {customRender: 'mother'}
        },
        {
          title: "出生日期",
          dataIndex: "birth_date",
          key: "birth_date",
          scopedSlots: {customRender: 'birth_date'}
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          scopedSlots: {customRender: 'gender'}
        },
        {
          title: '操作',
          key: "operation",
          scopedSlots: { customRender: 'operation' },
        },
      ],
      headStyle: {
        backgroundColor: '#186DF5 ', // 自定义 header 背景色
        color: '#efefef', // 自定义 header 文字颜色
        "border-radius": '10px 10px 0px 0px',
        "font-weight": 'bold'
      }
    }
  },
  created() {
    this.cell_id = this.$route.params.id
    this.group_type = this.$route.query.group_type
    if (this.group_type == 'cell') {
      this.$api.get_farm_cell(this.$route.params.id).then(res => {
        if (res.code == 200) {
          this.farm_cell = res.data
          if (res.data.gender_group.length == 1) {
            this.xuan_pei_query.gender = res.data.gender_group[0].item_gender == 0 ? 1 : 0
          }
          this.xuan_pei_query.farm_id = res.data.farm_id
          this.xuan_pei_query.farm_cell_id = res.data.id
          this.load_items()
          this.load_xuan_pei_items()
        }
      })
    } else if (this.group_type == 'selectRecord') {
      this.$api.get_item_select_record_by_id(this.cell_id).then(res => {
        this.farm_cell = res.data
        if (res.data.gender_group.length == 1) {
          this.xuan_pei_query.gender = res.data.gender_group[0].item_gender == 0 ? 1 : 0
        }
        this.xuan_pei_query.farm_id = res.data.farm_id
        this.xuan_pei_query.farm_cell_id = this.cell_id
        this.load_items()
        this.load_xuan_pei_items()
      })
    }
  },
  methods: {
    back() {
      this.$router.push('/farms')
    },
    load_items(page) {

      let query = {
        farm_cell_id: this.cell_id,
        group_type: this.group_type
      }
      if (page) {
        query['page_no'] = page.current
        query['page_size'] = page.pageSize
      }
      this.$api.get_breed_items(query).then(res => {
        if (res.code == 200) {
          this.items = res.data.list
          this.$refs.itemTable.open(res.data.list, res.data.page)
        }
      })
    },
    load_xuan_pei_items(page) {
      this.xuan_pei_query.group_type = this.group_type
      if (page) {
        this.xuan_pei_query.page_no = page.current
        this.xuan_pei_page.current = page.current
      } else {
        this.xuan_pei_query.page_no = 1
        this.xuan_pei_page.current = 1
      }
      this.$api.get_farm_cell_yu_zhong_result(this.xuan_pei_query).then(res => {
        if (res.code == 200) {
          this.xuan_pei_items = res.data
          this.xuan_pei_page = res.page
        }
      })
    },
    onReverseSelectChange() {
      this.load_yu_zhong_results(this.picked_male_item)
    },
    onYuZhongPageChange(page) {
      this.yu_zhong_result_query.page_no = page
      this.yu_zhong_result_query.gen_num = this.xuan_pei_query.gen_num
      this.load_yu_zhong_results(this.picked_male_item)
    },
    async load_yu_zhong_results(item) {
      this.yu_zhong_result_query.farm_cell_id = this.cell_id
      this.yu_zhong_result_query.id = item.id
      this.yu_zhong_result_query.group_type = this.group_type
      this.yu_zhong_result_query.gen_num = this.xuan_pei_query.gen_num
      if (this.picked_male_item.id != item.id) {
        this.yu_zhong_result_query.page_no = 1
      }
      this.picked_male_item = item
      const res = await this.$api.get_batch_yu_zhong_result(this.yu_zhong_result_query)
      this.yu_zhong_pagination.total = res.page.total
      this.yu_zhong_pagination.total_pages = res.page.total_pages
      console.log(res.data, this.yu_zhong_pagination);
      this.$refs.resultTable.syncData(res.data, this.yu_zhong_pagination)
    },
    open_yu_zhong_result(row) {
      this.show_yu_zhong_dialog = true
      this.picked_yu_zhong_item = row
      this.load_yu_zhong_results(row)
    },
    on_yu_zhong_dialog_close() {
      this.show_yu_zhong_dialog = false
      this.$refs.resultTable.syncData([], {})
    },
    on_state_select_change(checked) {
      if (checked) {
        this.xuan_pei_query.state = 2
        this.load_xuan_pei_items()
      } else {
        this.xuan_pei_query.state = undefined
        this.load_xuan_pei_items()
      }
    },
    batch_add_items() {
      this.$refs.farmCellAddItems.open(this.farm_cell, this.group_type)
    }
  }
}
</script>

<style>
</style>