<template>
  <a-modal 
    :visible="visible" 
    :title="title" 
    :width="750"
    @cancel="onCancel"
    @ok="onSave"
    cancelText="取消" okText="确认">
    <a-form :form="edit_item">
      <a-row :gutter="10">
        <a-col :span="8">
          <a-form-item label="RF Id">
            <a-input v-model="edit_item.rf_id"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="VId">
            <a-input v-model="edit_item.vid"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="性别">
            <a-select v-model="edit_item.gender">
              <a-select-option key="1" :value="1">公</a-select-option>
              <a-select-option key="1" :value="0">母</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="出生日期">
            <a-date-picker 
              v-model="edit_item.birth_date"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="死亡日期">
            <a-date-picker 
              v-model="edit_item.died_at"
            ></a-date-picker>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="10">
        <a-col :span="8">
          <a-form-item label="农场选择">
            <farm-select :default_id="edit_item.farm_id" @finish_select="on_farm_select"></farm-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="是否存栏">
            <a-select v-model="edit_item.is_kept" :disabled="edit_item.died_at">
              <a-select-option key="1" :value="1">存栏</a-select-option>
              <a-select-option key="0" :value="0">非存栏</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="个体状态">
            <a-select v-model="edit_item.state" :disabled="edit_item.died_at">
              <a-select-option key="null" :value="null">已入库</a-select-option>
              <a-select-option key="1" :value="1">候补</a-select-option>
              <a-select-option key="2" :value="2">可用</a-select-option>
              <a-select-option key="3" :value="3">不可用</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="10">
        <a-col :span="8">
          <a-form-item label="GEBV">
            <a-input v-model="edit_item.gebv_volume"></a-input>
          </a-form-item> 
        </a-col>
        <a-col :span="8">
          <a-form-item label="育种索引值">
            <a-input v-model="edit_item.breed_index"></a-input>
          </a-form-item> 
        </a-col>
      </a-row>
      <a-row :gutter="10">
        <a-col :span="12">
          <a-form-item label="父代VId">
            <item-select 
              :gender="1" 
              placeholder="请输入或选择父代VId"
              @finish-select="(e) => {edit_item.item_father = e}"
              ref="father_select">
            </item-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="母代VId">
              <item-select 
              :gender="0" 
              placeholder="请输入或选择母代VId"
              @finish-select="(e) => {edit_item.item_mother = e}"
              ref="mother_select">
            </item-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import itemSelect from './itemSelect.vue';
import farmSelect from './../../farms/components/farmSelect.vue'
export default {
  name: "breedItemEdit",
  components: {
    itemSelect, farmSelect
  },
  data() {
    return {
      edit_item: {},
      action: '',
      edit_item_template: {
        id: '',
        vid: '',
        state: null,
        father_id: null,
        mother_id: null,
        rf_id: '',
        gender: 0,
        item_mother: '',
        item_father: '',
        birth_date: '',
        died_at: null,
        gebv_volume: '',
        breed_index: '',
        color: 'White',
        breed_type_id: 2,
        is_kept: 0
      },
      visible: false,
      title: ''
    }
  },
  methods: {
    open(action, item) {
      this.action = action
      if (action == 'add') {
        this.title = '新增个体'
        this.edit_item = JSON.parse(JSON.stringify(this.edit_item_template))
        this.$nextTick(() => {
          this.$refs.father_select.onOpen('')
          this.$refs.mother_select.onOpen('')
        })
      } else {
        this.title = '编辑个体'
        this.edit_item = item
        this.$nextTick(() => {
          this.$refs.father_select.onOpen(item.item_father)
          this.$refs.mother_select.onOpen(item.item_mother)
        })
      }
      this.visible = true
    },
    onCancel() {
      this.visible = false
    },
    onSave() {
      let payload = JSON.parse(JSON.stringify(this.edit_item_template))

      Object.keys(payload).forEach(key => {
        if (this.edit_item[key]) {
          payload[key] = this.edit_item[key]
        }
      })
     
      if (payload.birth_date) {
        payload.birth_date = new Date(payload.birth_date).toLocaleDateString()
      }
      if (!payload.item_father && payload.father_id) {
        payload.father_id = null
      }
      if (!payload.item_mother && payload.mother_id) {
        payload.mother_id = null
      }
      if (this.action == 'add') {
        this.$api.post_breed_item(payload).then(res =>  {
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.visible = false
            this.edit_item = JSON.parse(JSON.stringify(this.edit_item_template))
            this.$emit('finish-edit', true)
          }
        })
      } else {
        this.$api.put_breed_item(payload.id, payload).then(res =>  {
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.visible = false
            this.edit_item = JSON.parse(JSON.stringify(this.edit_item_template))
            this.$emit('finish-edit', true)
          }
        })
      }
    },
    on_farm_select(farm_id) {
      this.edit_item.farm_id = farm_id
    },
  },
}
</script>

<style>
</style>