<template>
  <div>
    <a-row type="flex">
      <a-button type="primary" @click="onSelect">选择{{ is_male ? '雄性' : '雌性' }}个体</a-button>
      <a-radio-group v-model="is_male" class="margin-left-20">
        <a-radio-button :value="true">雄性</a-radio-button>
        <a-radio-button :value="false">雌性</a-radio-button>
      </a-radio-group>
      <div v-if="item.id" style="min-height: 40px;" class="margin-left-10 font-20 margin-top-5">
        已选择：
      </div>
    </a-row>
    <a-row v-if="item.id">
      <item-card :title="item.vid" :item="item" class="margin-top-10"></item-card>
    </a-row>
    <a-divider></a-divider>
    <a-row>
      <a-page-header title="匹配个体列表"></a-page-header>
      <a-result status="" title="提示" sub-title="请先选择目标个体" v-if="!item.id">
        <template #icon>
          <a-icon type="clock-circle" theme="twoTone" />
        </template>
      </a-result>
      <yu-zhong-result-table ref="resultTable" v-show="item.id"
        @page-change="onPageChange" :male_item="item"></yu-zhong-result-table>
    </a-row>
    <item-select ref="itemSelect" @select-over="onSelectOver"></item-select>
  </div>
</template>

<script>
import itemSelect from './itemSelect.vue';
import yuZhongResultTable from '@/views/yu_zhong/components/yuZhongResultTable'
import itemCard from '../../breed_items/components/itemCard.vue';
export default {
  components: {
    itemSelect, yuZhongResultTable, itemCard
  },
  data() {
    return {
      item: {id: null},
      list: [],
      is_male: true,
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
        showTotal: total => `共 ${total} 条记录`
      },
      query: {
        page_no: 1,
        page_size: 20,
        id: 1
      }
    }
  },
  methods: {
    onSelectOver(item) {
      this.item = item
      this.query.id = item.id
      this.refreshList()
    },
    onSelect() {
      this.$refs.itemSelect.open({
        gender: this.is_male ? 1 : 0
      })
    },
    async refreshList() {
      this.pagination.current = 1
      this.$refs.resultTable.syncData([], this.pagination)
      const res = await this.$api.get_batch_yu_zhong_result(this.query)
      this.pagination.total = res.page.total
      this.pagination.total_pages = res.page.total_pages
      this.pagination.pageSize = res.page.page_size
      this.$refs.resultTable.syncData(res.data, this.pagination)
    },
    onPageChange(page) {
      this.query.page_no = page
      this.refreshList()
    }
  },
}
</script>

<style>
</style>