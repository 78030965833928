
import api from '@/api'
import util from '@/libs/util'
import { message } from 'ant-design-vue'

export default {
  namespaced: true,
  state: {
    // 用户信息
    info: JSON.parse(localStorage.getItem('aoxy-user'))
  },
  getters: {
    info: state => state.info
  },
  actions: {
    getUserInfo({ state, commit, dispatch }) {
      const get_user_info = async () => {
        let userid = util.cookies.get('user_id')
        return await api.get_user(userid)
      }

      return new Promise((resolve, reject) => {
        get_user_info().then(res => {
          if (res.code == 200) {
            resolve(res.data.user)
            dispatch('set', res.data.user)
          } else {
            reject()
          }
        })
      })
    },
    login({ commit, dispatch }, form) {
      return new Promise((resolve, reject) => {
        api.user_login(form)
          .then(res => {
            if (res.code === 200) {
              util.cookies.set("token", res.data.token);
              localStorage.setItem("aoxy-user", JSON.stringify(res.data.user))
              resolve(res);
            } else {
              message.error(res.message)
              reject(new Error(`Login failed with code ${res.code}`));
            }
          })
          .catch(reject);
      });
    },
    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        util.cookies.set("token", null)
        localStorage.setItem("aoxy-user", null)
        resolve(true)
      });
      
    }

    // /**
    //  * @description 设置用户数据
    //  * @param {Object} context
    //  * @param {*} info info
    //  */
    // async set({ state, dispatch }, info) {
    //   // store 赋值
    //   state.info = info
    //   // 持久化
    //   await dispatch('user/info', {
    //     dbName: 'sys',
    //     path: 'user.info',
    //     value: info,
    //     user: true
    //   }, { root: true })
    // },
    // /**
    //  * @description 从数据库取用户数据
    //  * @param {Object} context
    //  */
    // async load({ state, dispatch }) {
    //   // store 赋值
    //   state.info = await dispatch('d2admin/db/get', {
    //     dbName: 'sys',
    //     path: 'user.info',
    //     defaultValue: {},
    //     user: true
    //   }, { root: true })
    // }
  }
}
