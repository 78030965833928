<template>
  <a-card :bordered="true" class="dashboard-bar-chart">
    <a-page-header 
      :title="item_data.vid"
      @back="() => this.$router.go(-1)"
      sub-title="个体详情" >
      <template slot="extra">
        <a-button type="primary" size="small" @click="openEdit('edit', item_data)">编辑</a-button>
      </template>
    </a-page-header>
    <a-row v-if="loading" type="flex">
      <a-col :span="24" class="flex-center">
        <a-spin></a-spin>
      </a-col>
    </a-row>
    <div v-show="!loading">
      <div class="margin-top-20">
        <h3>个体系谱图</h3>
      </div>
      <a-divider></a-divider>
    </div>
    
    <div class="padding-right-20">
      <item-tree-chart ref="chart"></item-tree-chart>
    </div>
    <a-row v-if="!loading" class="margin-top-20">
      <a-col :span="24">
        <detail-section :item_data="item_data"></detail-section>
      </a-col>
    </a-row>
    <a-row v-if="!loading">
      <a-col :span="12">
        <detail-section :item_data="item_data.f_node" :show_link="true" title="父代信息"
          v-if="item_data.f_node"></detail-section>
      </a-col>
      <a-col :span="12">
        <detail-section :item_data="item_data.m_node" :show_link="true" title="母代信息"
          v-if="item_data.m_node"></detail-section>
      </a-col>
    </a-row>
    <a-row v-if="!loading">
      <a-col :span="6">
        <detail-section size="small" :item_data="item_data.f_node.f_node" title="祖父代信息"
          :show_link="true" v-if="item_data.f_node && item_data.f_node.f_node" :column="2"></detail-section>
      </a-col>
      <a-col :span="6">
        <detail-section size="small" :item_data="item_data.f_node.m_node" title="祖母代信息"
          :show_link="true" v-if="item_data.f_node && item_data.f_node.m_node" :column="2"></detail-section>
      </a-col>
      <a-col :span="6">
        <detail-section size="small" :item_data="item_data.m_node.f_node" title="外祖父代信息"
          :show_link="true" v-if="item_data.m_node && item_data.m_node.f_node" :column="2"></detail-section>
      </a-col>
      <a-col :span="6">
        <detail-section size="small" :item_data="item_data.m_node.m_node" title="外祖母代信息"
          :show_link="true" v-if="item_data.m_node && item_data.m_node.m_node" :column="2"></detail-section>
      </a-col>
    </a-row>

    <add-or-edit ref="addOrEdit"></add-or-edit>
  </a-card>
</template>

<script>
import detailSection from '@/views/breed_items/components/detailSection'
import itemTreeChart from '../yu_zhong/components/itemTreeChart.vue';
import addOrEdit from '@/views/breed_items/components/addOrEdit'
export default {
  name: "breed_items_detail",
  components: {
    detailSection, itemTreeChart, addOrEdit
  },
  data() {
    return {
      loading: false,
      id: 0,
      vid: "",
      rf_id: "",
      father: "",
      mother: "",
      birth_date: "",
      gender: "",
      item_data: {},
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadData(this.id)
  },
  watch: {
    $route(to, from) {
      this.loadData(to.params.id)
    }
  },
  methods: {
    loadData(id) {
      this.loading = true
      this.$api.get_breed_item_by_id(id).then((res) => {
        this.item_data = res.data
        this.loading = false
        this.$refs.chart.draw(res.tree_data)
      });
    },
    openEdit(action, item) {
      if (action == 'add') {
        this.$refs.addOrEdit.open('add', item)
      } else {
        this.$refs.addOrEdit.open('edit', item)
      }
    },
  }
}
</script>