<template>
  <div ref="chart" style="width: 100%; height: 500px"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    draw(tree_data) {
      const my_chart = this.$echarts.init(this.$refs.chart);
      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "tree",
            data: [tree_data],
            top: "1%",
            left: "7%",
            bottom: "1%",
            right: "20%",
            symbolSize: 10,
            initialTreeDepth: null,
            roam: true,
            expandAndCollapse: false,
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 9,
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
            },
            emphasis: {
              focus: "descendant",
            },
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };

      this.$nextTick(() => {
        my_chart.setOption(option)
      });
    },
  },
};
</script>

<style></style>
