<template>
  <div>
    <a-auto-complete
      v-model="vid"
      @change="onChange"
      :data-source="list"
      @search="onSearch"
      backfill
      allowClear
      :placeholder="placeholder"
    >
    </a-auto-complete>
  </div>
</template>

<script>
export default {
  name: "breedItemSelect",
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    gender: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      list: [],
      triggered: false,
      vid: undefined,
      query: {
        page_no: 1,
        page_size: 20,
        vid: '',
        gender: 0
      }
    }
  },
  created() {
    this.query.vid = this.vid
    this.refreshList()
  },
  methods: {
    async refreshList() {
      let _self = this
      this.query.gender = this.gender
      const res = await this.$api.get_breed_item_selection(this.query)
      this.$nextTick(() => {  
        if (res.list.length > 0) {
          _self.list = res.list.map(o => o.label)
        }
      })
    },
    onSearch(e) {
      this.query.vid = e
      this.refreshList()
    },
    onChange(e) {
      console.log(this.list);
      this.$emit('finish-select', e)
    },
    async onOpen(vid) {
      this.query.vid = vid
      this.vid = vid
      this.refreshList()
    }
  },
}
</script>

<style>
</style>