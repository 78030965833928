<template>
  <a-modal 
    :visible="visible" 
    :title="title" 
    :width="750"
    @cancel="onCancel"
    @ok="onSave"
    cancelText="取消" okText="确认">
    <a-form :form="edit_item">
      <a-row :gutter="10">
        <a-col :span="8">
          <a-form-item label="牧场名称">
            <a-input v-model="edit_item.name" class="width-100"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="10">
        <a-col :span="8">
          <a-form-item label="地址">
            <a-input v-model="edit_item.location" class="width-100"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: "breedItemEdit",
  components: {
  },
  data() {
    return {
      action: '',
      edit_item: {},
      edit_item_template: {
        name: '',
        location: ''
      },
      visible: false,
      title: ''
    }
  },
  methods: {
    open(action, item) {
      let _self = this
      this.action = action
      if (action == 'add') {
        this.title = '新增牧场'
        this.edit_item = JSON.parse(JSON.stringify(this.edit_item_template))
      } else {
        this.title = '编辑牧场'
        this.edit_item = item
      }
      this.visible = true
    },
    onCancel() {
      this.visible = false
    },
    onSave() {
      if (this.action == 'add') {
        this.$api.post_farm(this.edit_item).then(res =>  {
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.visible = false
            this.edit_item = JSON.parse(JSON.stringify(this.edit_item_template))
            this.$emit('finish-edit')
          }
        })
      } else {
        this.$api.put_farm(this.edit_item).then(res =>  {
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.visible = false
            this.edit_item = JSON.parse(JSON.stringify(this.edit_item_template))
            this.$emit('finish-edit')
          }
        })
      }
    },
    on_farm_select(farm_id) {
      this.edit_item.farm_id = farm_id
    },
  },
}
</script>

<style>
</style>