import { render, staticRenderFns } from "./CardPaymentMethods.vue?vue&type=template&id=05b8c6bc"
import script from "./CardPaymentMethods.vue?vue&type=script&lang=js"
export * from "./CardPaymentMethods.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports