<template>
  <a-modal
    :visible="visible"
    title="选择个体"
    @cancel="on_close"
    :width="1000"
  >
    <a-page-header>
      <a-input
        v-model="query.vid"
        placeholder="请输入编号"
        @blur="refresh_list"
        allowClear
        style="width: 200px"
      ></a-input>
      <a-select
        v-model="query.farm_id"
        allowClear
        @change="refresh_list"
        placeholder="请选择农场"
        style="width: 150px; margin-left: 10px"
      >
        <a-select-option
          :value="farm.value"
          v-for="(farm, index) in farms"
          :key="index"
          >{{ farm.label }}</a-select-option
        >
      </a-select>
    </a-page-header>
    <a-table
      :data-source="list"
      :columns="columns"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="vid" slot-scope="vid, row">
        {{ vid || "-" }}
      </template>
      <template slot="export_tag" slot-scope="export_tag, row">
        {{ export_tag || "-" }}
      </template>
      <template slot="rf_id" slot-scope="rf_id, farm_id">
        {{ rf_id || "-" }}
      </template>
      <template slot="father" slot-scope="father">
        {{ father || "-" }}
      </template>
      <template slot="mother" slot-scope="mother">
        {{ mother || "-" }} </template
      >ujhyh7
      <template slot="birth_date" slot-scope="birth_date">
        {{ birth_date || "-" }}
      </template>
      <template slot="gender" slot-scope="gender">
        {{ gender == 0 ? "母" : "公" }}
      </template>
      <template slot="select" slot-scope="select">
        <a-button size="small" type="primary" @click="onConfirm(select)">选择</a-button>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
export default {
  name: "ItemSelect",
  data() {
    return {
      visible: false,
      query: {
        gender: 0,
        vid: "",
        page_no: 1,
        page_size: 20,
        farm_id: undefined,
      },
      farms: [],
      list: [],
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
        showTotal: (total) => `共 ${total} 条记录`,
      },
      columns: [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Vid",
          dataIndex: "vid",
          key: "vid",
          scopedSlots: { customRender: "vid" },
        },
        {
          title: "出口号",
          dataIndex: "export_tag",
          key: "export_tag",
          scopedSlots: { customRender: "export_tag" },
        },
        {
          title: "RF-Id",
          dataIndex: "rf_id",
          key: "rf_id",
          scopedSlots: { customRender: "rf_id" },
        },
        {
          title: "父亲",
          dataIndex: "item_father",
          key: "father",
          scopedSlots: { customRender: "father" },
        },
        {
          title: "母亲",
          dataIndex: "item_mother",
          key: "mother",
          scopedSlots: { customRender: "mother" },
        },
        {
          title: "出生日期",
          dataIndex: "birth_date",
          key: "birth_date",
          scopedSlots: { customRender: "birth_date" },
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          scopedSlots: { customRender: "gender" },
        },
        {
          title: "选择",
          scopedSlots: { customRender: "select" },
        },
      ],
    };
  },
  methods: {
    open(options) {
      this.list = []
      this.visible = true;
      this.query.gender = options.gender;
      this.refresh_list();
      this.$api.get_farms().then((res) => {
        this.farms = res.data.list.map((o) => {
          return {
            label: o.name,
            value: o.id,
          };
        });
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.query.page_no = pagination.current;
      this.pagination = pagination;
      this.refresh_list();
    },
    refresh_list() {
      this.list = []
      this.$api.get_breed_items(this.query).then((res) => {
        this.list = res.data.list;
        this.pagination.total = res.data.page.total;
        this.pagination.total_pages = res.data.page.total_pages;
      });
    },
    onConfirm(row) {
      this.$emit('select-over', row)
      this.visible = false
    },
    on_close() {
      this.visible = false
      this.list = []
      this.query.vid = ''
    }
  },
};
</script>

<style></style>
