
export default ({ request }) => ({
  get_breed_items(params) {
    return request({
      url: '/v1/breed_items/list',
      method: 'get',
      params: params
    })
  },
  get_breed_item_selection(params) {
    return request({
      url: '/v1/breed_items/select',
      method: 'get',
      params: params
    })
  },
  get_breed_item_by_id(id) {
    return request({
      url: `/v1/breed_items/${id}`,
      method: 'get',
    })
  },
  get_breed_item_tree_by_id(id) {
    return request({
      url: `/v1/breed_items/${id}/tree`,
      method: 'get',
    })
  },
  get_yu_zhong_result(params) {
    return request({
      url: `/v1/breed_items/yu_zhong_result`,
      method: 'get',
      params: params
    })
  },
  get_farm_cell_yu_zhong_result(params) {
    return request({
      url: `/v1/breed_items/farm_cell_yu_zhong_result`,
      method: 'get',
      params: params
    })
  },
  get_batch_yu_zhong_result(params) {
    return request({
      url: `/v1/breed_items/batch_results`,
      method: 'get',
      params: params
    })
  },
  get_breed_item_graph(params) {
    return request({
      url: `/v1/breed_items/graph`,
      method: 'get',
      params: params
    })
  },
  post_breed_item(params) {
    return request({
      url: `/v1/breed_items`,
      method: 'post',
      data: params
    })
  },
  put_breed_item(id, params) {
    return request({
      url: `/v1/breed_items/${id}`,
      method: 'put',
      data: params
    })
  },
  get_gebvs_by_generations(vid) {
    return request({
      url: `/v1/breed_items/${vid}/gebvs`,
      method: 'get'
    })
  },
  post_match_items_with_tags(params) {
    return request({
      url: `/v1/breed_items/match_items_with_tags`,
      method: 'post',
      data: params
    })
  }
})
