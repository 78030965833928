
export default ({ request }) => ({
  get_tag_by_id(id) {
    return request({
      url: `/v1/item_tags/${id}`,
      method: 'get'
    })
  },
  get_tags(params) {
    return request({
      url: '/v1/item_tags',
      method: 'get',
      params: params
    })
  }
})
