<template>
  <a-card>
    <template slot="title">
      <div class="flex-left">
        <div class="tile"></div>
        <strong class="margin-left-10">VID: {{ chart_data.name }}</strong>
      </div>
    </template>
    <div ref="chart" :style="{'width': '100%', 'height': '800px'}"></div>
    <a-row class="margin-top-10">
      <a-button type="primary" @click="get_gebv_chart_data" :loading="loading">查看代际GEBV数据统计</a-button>
    </a-row>
    <div v-show="show_gebv_chart" class="width-100 margin-top-10">
      <div ref="gebv_chart" style="width: 100% !important;height: 300px"> </div>
    </div>
  </a-card>
</template>

<script>
export default {
  props: {
    chart_data: {
      type: Object, default: () => {}
    }
  },
  data() {
    return {
      height: 100,
      show_gebv_chart: false,
      loading: false
    }
  },
  mounted() {
    this.draw(this.chart_data)
  },
  methods: {
    draw(tree_data) {
      const my_chart = this.$echarts.init(this.$refs.chart);
      const series = {
        type: "tree",
        data: [tree_data],
        name: tree_data.vid,
        top: "10%",
        left: `10%`,
        symbolSize: 10,
        layout: "radial",
        roam: true,
        expandAndCollapse: false,
        initialTreeDepth: 2,
        label: {
          position: "left",
          verticalAlign: "middle",
          align: "right",
          fontSize: 9,
        },
        leaves: {
          label: {
            position: "right",
            verticalAlign: "middle",
            align: "left",
          },
        },
        emphasis: {
          focus: "descendant",
        },
        animationDuration: 550,
        animationDurationUpdate: 750,
      }

      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: series,
      };

      this.$nextTick(() => {
        my_chart.setOption(option)
      })
    },
    async get_gebv_chart_data() {
      this.loading = true
      const res = await this.$api.get_gebvs_by_generations(this.chart_data.name)
      this.loading = false
      this.show_gebv_chart = true

      this.$nextTick(() => {

        const gebv_chart = this.$echarts.init(this.$refs.gebv_chart);
        console.log(gebv_chart);
    
        let option = {
          title: [
            {
              text: `${this.chart_data.name}代际GEBV变化`,
              left: 'center'
            }
            
          ],
          dataset: [
            {
              source: res.data
            },
            {
              transform: {
                type: 'boxplot',
                config: { itemNameFormatter: '{value}代' }
              }
            },
            {
              fromDatasetIndex: 1,
              fromTransformResult: 1
            }
          ],
          tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '10%',
            right: '10%',
            bottom: '15%'
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            nameGap: 30,
            splitArea: {
              show: false
            },
            splitLine: {
              show: false
            }
          },
          yAxis: {
            type: '',
            name: 'gebv',
            splitArea: {
              show: true
            }
          },
          series: [
            {
              name: 'boxplot',
              type: 'boxplot',
              datasetIndex: 1
            },
            {
              name: 'outlier',
              type: 'scatter',
              datasetIndex: 2
            }
          ]
        }
        this.$nextTick(() => {
          gebv_chart.setOption(option)
        })
      })
    }
  },
}
</script>

<style>
</style>