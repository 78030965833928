<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">
    <a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">
      <!-- Sign In Form Column -->
      <a-col
        :span="18"
        :md="12"
        :lg="{ span: 12, offset: 0 }"
        :xl="{ span: 6, offset: 2 }"
        class="col-form"
      >
        <h1 class="mb-15">艾捷思育种信息系统</h1>
        <h5 class="font-regular text-muted">畜牧智能管理</h5>

        <!-- Sign In Form -->
        <a-form
					ref="form"
          id="components-form-demo-normal-login"
          class="login-form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
        >
          <a-form-item class="mb-10" label="手机号" :colon="false">
            <a-input
              v-model="form.mobile"
              v-decorator="[
                'email',
                { rules: [{ required: true, message: '请输入手机号' }] },
              ]"
              placeholder="手机号"
            />
          </a-form-item>
          <a-form-item class="mb-5" label="Password" :colon="false">
            <a-input
              v-model="form.user_password"
              v-decorator="[
                'password',
                { rules: [{ required: true, message: '请输入密码' }] },
              ]"
              type="password"
              placeholder="密码"
            />
          </a-form-item>
          <a-form-item class="mb-10">
            <!-- <a-switch v-model="rememberMe" />  -->
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->

        <!-- <p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p> -->
      </a-col>
      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <a-col :span="12" :md="12" :lg="12" :xl="12" class="col-img">
      </a-col>
      <!-- Sign In Image Column -->
    </a-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      form: {
				id: "",
				user_name: "",
				user_password: "",
				user_mail: "",
				nickname: "",
				mobile: "",
				role: 1
      },
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.$store.dispatch('main/user/login', this.form).then((res) => {
        this.$router.replace({name: "首页"});
      });
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>