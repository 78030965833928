<template>
  <a-modal 
    :visible="visible" 
    title="批量添加个体" 
    :width="750"
    @cancel="onCancel"
    @ok="onSave"
    :ok-button-props="{props: {disabled: preselected_items.length == 0, shape: 'round'}}"
    :cancel-button-props="{props: {shape: 'round'}}"
    cancelText="取消" okText="确认">
    <a-form :form="edit_item">
      <span class="light-grey-text">*注:号码之间用 / 分隔</span>
      <a-form-item label="号码列表">
        <a-input type="textarea" :auto-size="{minRows: 5, maxRows: 15}"
                  v-model="preselect_payload.item_tags"></a-input>
      </a-form-item>
      
      <a-button type="primary" size="small" shape="round" @click="preselect_items">匹配个体编号</a-button>

      <a-card v-if="show_match_result" size="small" class="margin-top-20" bordered="false">
        <a-row>
          <a-col :span="12">
            <span class="light-grey-text" v-if="preselected_items.length > 0">
              已匹配: <span class="green-text">{{preselected_items.length}}</span>
            </span>
            <a-row v-for="item in preselected_items">
              <a-tag color="green" class="margin-top-5">{{item.vid}} / {{ item.export_tag }} {{ item.item_gender == 1 ? '公' : '母'}}</a-tag>
            </a-row>
          </a-col>

          <a-col :span="12">
            <span class="light-grey-text">
              未匹配：<span class="red-text">{{unmatched_items.length}}</span>
            </span>
            <a-row v-for="item in unmatched_items">
              <a-tag color="red" class="margin-top-5">{{item}}</a-tag>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: "farmCellAddItems",
  components: {
  },
  data() {
    return {
      edit_item: {},
      edit_item_template: {
        name: '',
        location: ''
      },
      visible: false,
      farm: {},
      group_type: '',
      preselected_items: [],
      unmatched_items: [],
      preselect_payload: {
        item_tags: '',
        farm_id: 0
      },
      payload: {
        farm_id: 0,
        group_type: 'cell',
        cell_id: 0,
        selected_items: []
      },
      show_match_result: false
    }
  },
  methods: {
    open(farm_cell, group_type) {
      this.farm = farm_cell.farm
      this.preselect_items.farm_id = farm_cell.farm.id
      this.payload.farm_id = farm_cell.farm.id
      this.payload.cell_id = farm_cell.id
      this.payload.group_type = group_type
      this.group_type = group_type
      this.visible = true
    },
    onCancel() {
      this.visible = false
    },
    onSave() {
      let confirm_title = this.unmatched_items.length > 0 ? '有未匹配个体，确认提交？' : '确认提交？'
      this.$confirm({
        title: '操作确认',
        content: confirm_title,
        okText: '确认', // 确认按钮文本
        cancelText: '取消', // 取消按钮文本
        class: 'custom-confirm', // 自定义类名，用于应用额外样式
        onOk: () => {
          this.payload.selected_items = this.preselected_items
          this.$api.post_add_farm_cell_items(this.payload).then(res => {
            if (res.code === 200) {
              this.$emit('finish-edit', true);
              this.visible = false;
            }
          });
        },
        onCancel() {
          console.log('点击了取消');
        }
      });
    },
    preselect_items() {
      this.$api.post_match_items_with_tags(this.preselect_payload).then(res => {
        if (res.code == 200) {
          this.show_match_result = true
          this.preselected_items = res.data.result
          this.unmatched_items = res.data.unmatched
          this.$message.success("匹配完成")
        }
      })
    }
  },
}
</script>

<style>
</style>