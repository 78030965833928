<template>
  <a-card :bordered="true" class="dashboard-bar-chart container">
    <a-tabs @change="onTabChange">
      <a-tab-pane key="1" tab="育种匹配记录">
        <yu-zhong-records-table ref="yuZhongRecords"></yu-zhong-records-table>
      </a-tab-pane>

      <a-tab-pane key="2" tab="批量匹配">
        <batch-yu-zhong></batch-yu-zhong>
      </a-tab-pane>

      <a-tab-pane key="3" tab="个体匹配">
        <a-row :gutter="24" class="padding-bottom-20">
          <a-col :span="11">
            <a-row type="flex">
              <a-button type="primary" @click="openSelect(1)">选择雄性个体</a-button>
            </a-row>
            <a-row v-if="male" class="margin-top-20 padding-bottom-20">
              <item-card :title="male.vid" :item="male"></item-card>
            </a-row>
            <item-tree-chart ref="maleChart" v-if="finish_query"></item-tree-chart>
          </a-col>
          <a-col :span="11">
            <a-row type="flex">
              <a-button type="primary" @click="openSelect(0)">选择雌性个体</a-button>
            </a-row>
            <a-row v-if="female" class="margin-top-20 padding-bottom-20">
              <item-card :title="female.vid" :item="female"></item-card>
            </a-row>
            <item-tree-chart ref="femaleChart" v-if="finish_query" ></item-tree-chart>
          </a-col>
          <a-col :span="2">
            <a-button type="primary" :disabled="!(male && female)" @click="get_result" :loading="ge_ti_result_loading">查看结果</a-button>
          </a-col>
        </a-row>
        <a-result status="" title="提示" sub-title="请先选择目标个体" v-if="!(male && female)">
          <template #icon>
            <a-icon type="clock-circle" theme="twoTone" />
          </template>
        </a-result>
      </a-tab-pane>

      <a-tab-pane key="4" tab="家系图谱">
        <family-graph-list></family-graph-list>
      </a-tab-pane>
     
    </a-tabs>
    <item-select ref="itemSelect" @select-over="onSelectOver"></item-select>
  </a-card>
</template>

<script>
import itemSelect from './components/itemSelect.vue';
import itemTreeChart from './components/itemTreeChart.vue';
import batchYuZhong from '@/views/yu_zhong/components/batchYuZhong'
import yuZhongRecordsTable from './components/yuZhongRecordsTable.vue';
import familyGraphList from '@/views/yu_zhong/components/familyGraphList'
import itemCard from '../breed_items/components/itemCard.vue';
export default {
  name: "breed_items",
  components: {
    itemSelect, itemTreeChart, batchYuZhong, yuZhongRecordsTable, familyGraphList, itemCard
  },
  data() {
    return {
      male: '',
      female: '',
      male_tree: '',
      female_tree: '',
      finish_query: false,
      step: 1,
      ge_ti_result_loading: false
    }
  },
  created() {
    this.onTabChange(1)
    
  },
  methods: {
    openSelect(gender) {
      this.$refs.itemSelect.open({
        gender: gender,
      })
    },
    onSelectOver(item) {
      if (item.gender == 1) {
        this.male = item
      } else {
        this.female = item
      }
    },
    async get_result(){
      this.ge_ti_result_loading = true
      const res = await this.$api.get_yu_zhong_result({
        male_id: this.male.id,
        female_id: this.female.id
      })
      this.finish_query = true
      this.ge_ti_result_loading = false
      this.$nextTick(() => {
        this.$refs.maleChart.draw(res.data.male_chart_data)
        this.$refs.femaleChart.draw(res.data.female_chart_data)
      })
    },
    onTabChange(e) {
      // 切换育种记录重新加载列表
      if (e == 1) {
        this.$nextTick(() => {
          this.$refs.yuZhongRecords.refresh_list()
        })
      }
    }
  },
}
</script>

<style>
</style>
