import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '../libs/util'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
		layout: "dashboard",
	},
	{
		path: '/breed_items',
		name: '个体信息',
		layout: "dashboard",
		meta: {
			auth: true
		},
		component: () => import('../views/breed_items/index.vue'),
	},
	{
		path: '/breed_items/:id',
		name: '个体详情',
		layout: "dashboard",
		meta: {
			auth: true
		},
		component: () => import('../views/breed_items/detail.vue'),
	},
	{
		path: '/yu_zhong',
		name: '选配计划',
		layout: "dashboard",
		meta: {
			auth: true
		},
		component: () => import('../views/yu_zhong/index.vue'),
	},
	{
		path: '/dashboard',
		name: '首页',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/Dashboard.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	{
		path: '/tables',
		name: 'Tables',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
	},
	{
		path: '/farms',
		name: '牧场管理',
		layout: "dashboard",
		meta: {
			auth: true
		},
		component: () => import('../views/farms/index.vue'),
	},
	{
		path: '/farms/cell/:id',
		name: '农舍管理',
		layout: "dashboard",
		meta: {
			auth: true
		},
		component: () => import('../views/farms/farmCell.vue'),
	},
	{
		path: '/users/wode',
		name: '我的账号',
		layout: "dashboard",
		meta: {
			auth: true
		},
		component: () => import('../views/users/wode.vue'),
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach(async (to, from, next) => {
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some((r) => r.meta.auth)) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    const token = util.cookies.get("token");
    if (token && token !== "undefined") {
      // next()
			next()
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        path: "/sign-in",
      });
      // https://github.com/d2-projects/d2-admin/issues/138
    }
  } else {
    // 不需要身份校验 直接通过
    next();
  }
});

export default router
