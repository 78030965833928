<template>
<a-card :bordered="true" class="dashboard-bar-chart container" title="我的账号">
  <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
    <a-form-item label="姓名" required>
      <a-input v-model="form.user_name"></a-input>
    </a-form-item>
    <a-form-item label="手机">
      <a-input v-model="form.mobile" :disabled="true"></a-input>
    </a-form-item>
    <a-form-item label="密码">
      <a-input type="password" v-model="form.user_password"></a-input>
    </a-form-item>
    <a-form-item label="密码确认">
      <a-input type="password" v-model="password_confirm"></a-input>
    </a-form-item>
    <div class="width-100 flex flex-center" style="height: 50px">
      <a-button class="submit-btn" style="width: 200px" type="primary" @click="submit">保存</a-button>
    </div>
  </a-form>
</a-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      form: {
        user_name: '',
        mobile: '',
        user_password: ''
      },
      password_confirm: ''
    }
  },
  computed: {
    ...mapGetters('main/user', ['info'])
  },
  mounted() {
    this.form = this.info
  },
  methods: {
    submit() {
      if (this.form.password && this.form.password != this.password_confirm) {
        this.$message.error('两次密码输入不一致')
        return 
      }
      this.$api.update_user(this.info.id, this.form).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
}
</script>

<style scoped>
.submit-btn {
  position: fixed;
  float: right;
}
</style>