<template>
  <div :class="item.gender == 1 ? 'cell-box male-left-border' : 'cell-box female-left-border'" style="height: 120px">
    <a-row>
      <a-col :span="18" @click="to_item_info" >
        <a-row>
          <div class="font-20">{{ title }}</div>
        </a-row>
        <a-row>
          <span class="font-10">出口号：</span> <span class="primary-color">{{ item.export_tag}}</span>
        </a-row>
        <a-row>
          <span class="font-10">GEBV：</span> <span class="primary-color">{{ item.gebv_volume  }}</span>
        </a-row>
        <a-row>
          <span class="font-10">出生日期：</span> <span class="primary-color">{{ item.birth_date  }}</span>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    sub_title: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    to_item_info() {
      this.$router.push({
        path: `/breed_items/${this.item.id}`,
      }).catch(err => {});
    }
  }
}
</script>

<style>
</style>