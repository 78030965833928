
export default ({ request }) => ({
  get_farm_cells(params) {
    return request({
      url: '/v1/farms/farm_cells',
      method: 'get',
      params: params
    })
  },
  get_farm_cell(id) {
    return request({
      url: `/v1/farms/farm_cells/${id}`,
      method: 'get'
    })
  },
  get_farms(params) {
    return request({
      url: '/v1/farms',
      method: 'get',
      data: params
    })
  },
  post_farm(params) {
    return request({
      url: '/v1/farms',
      method: 'post',
      data: params
    })
  },
  put_farm(params) {
    return request({
      url: '/v1/farms',
      method: 'put',
      data: params
    })
  },
  post_farm_cell(params) {
    return request({
      url: '/v1/farms/farm_cell',
      method: 'post',
      data: params
    })
  },
  put_farm_cell(params) {
    return request({
      url: '/v1/farms/farm_cell',
      method: 'put',
      data: params
    })
  },
  delete_farm_cell(id) {
    return request({
      url: `/v1/farms/farm_cell/${id}`,
      method: 'delete'
    })
  },
  get_farm_groups(params) {
    return request({
      url: '/v1/farms/farm_groups',
      method: 'get',
      params: params
    })
  },
  post_add_farm_cell_items(params) {
    return request({
      url: '/v1/farms/add_farm_cell_items',
      method: 'post',
      data: params
    })
  }
})
