<template>
  <div>
    <a-select v-model="farm_id" allowClear @change="finish_select"
      placeholder="请选择农场" style="width: 150px; margin-left:10px">
      <a-select-option :value="farm.value" v-for="farm, index in farms" :key="index">{{ farm.label }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
  export default {
    props: {
      default_selection: {
        type: Boolean, default: false
      },
      default_id: {
        type: Number
      }
    },
    data() {
      return {
        farms: [],
        farm_id: this.default_id
      }
    },
    created() {
      this.$api.get_farms().then((res) => {
        this.farms = res.data.list.map((o) => {
          return {
            label: o.name,
            value: o.id,
          };
        });
        if (this.default_selection) {
          this.farm_id = this.farms[0].value
          this.$emit('finish_select', this.farms[0].value)
        }
      });
    },
    methods: {
      finish_select() {
        this.$emit('finish_select', this.farm_id)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>