<template>
  <div>
    <a-table 
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="male_vid" slot-scope="male_id, row">
        <item-button :text="`${row.male_vid}`" :id="row.male_id"></item-button>
      </template>
      <template slot="female_vid" slot-scope="female_id, row">
        <item-button :text="`${row.female_vid}`" :id="row.female_id"></item-button>
      </template>
      <template slot="operation" slot-scope="scope">
        <a-dropdown>
          <a-menu slot="overlay" @click="(e) => handleItemAction(e, scope)">
            <a-menu-item key="add_schedule"> <a-icon type="schedule" />加入排期</a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px" size="small"> 操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import ItemButton from '../../components/ItemButton.vue'
export default {
  name: "yuZhongRecordsTable",
  components: {
    ItemButton
  },
  data() {
    return {
      list: [],
      query: {
        page_no: 1,
        page_size: 20,
      },
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
        showTotal: total => `共 ${total} 条记录`
      },
      columns: [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "雄性供体VId",
          dataIndex: "male_id",
          key: "male_id",
          scopedSlots: {customRender: 'male_vid'}
        },
        {
          title: "雌性供体VId",
          dataIndex: "female_id",
          key: "female_id",
          scopedSlots: {customRender: 'female_vid'}
        },
        {title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } }
      ]
    }
  },
  methods: {
    refresh_list() {
      this.$api.get_yu_zhong_records(this.query).then(res => {
        this.list = res.list
        this.pagination.total = res.page.total
      })
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.query.page_no = pagination.current
      this.refresh_list()
    },
    handleItemAction(e, row) {

    }
  },
}
</script>

<style>
</style>