<template>
  <div>
    <a-row>
      <a-col :span="24" class="flex-left">
        <farm-select :default_selection="true" @finish_select="load_graph"></farm-select>
        <a-descriptions :column="2" class="margin-left-20 margin-top-10">
          <a-descriptions-item label="当前家系数量">{{ tree_datas.length || 0}}</a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="flex-center">
        <a-spin v-if="loading"></a-spin>
      </a-col>
    </a-row>
    <family-graph 
      v-for="tree_data, index in tree_datas" :key="index" 
      :chart_data="tree_data" 
      class="margin-top-10"></family-graph>
  </div>
</template>

<script>
import familyGraph from '@/views/yu_zhong/components/familyGraph'
import farmSelect from '../../farms/components/farmSelect.vue'
export default {
  components: {familyGraph, farmSelect},
  data() {
    return {
      tree_datas: [],
      loading: false
    }
  },
  created() {
    this.loading = true
  },
  methods: {
    load_graph(farm_id) {
      this.$api.get_breed_item_graph({
        farm_id: farm_id
      }).then(res => {
        this.loading = false
        this.tree_datas = res.data.graph_data
      })
    }
  }
}
</script>

<style>
</style>