<template>
  <div>
    <a-table
      :data-source="list"
      :columns="columns"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="vid" slot-scope="vid, row">
        <item-button :text="vid" :id="row.id || row.rf_id || row.id" :is_dead="row.is_dead"></item-button>
      </template>
      <template slot="export_tag" slot-scope="export_tag, farm_id">
        {{ export_tag || '-' }}
      </template>
      <template slot="rf_id" slot-scope="rf_id, farm_id">
        {{ rf_id || '-' }}
      </template>
      <template slot="father" slot-scope="father, row">
        <item-button :text="row.item_father" :id="row.father_id" :is_dead="row.is_dead"></item-button>
      </template>
      <template slot="mother" slot-scope="mother, row">
        <item-button :text="row.item_mother" :id="row.mother_id" :is_dead="row.is_dead"></item-button>
      </template>
      <template slot="birth_date" slot-scope="birth_date">
        {{ birth_date || '-' }}
      </template>
      <template slot="gender" slot-scope="gender">
        {{ gender == 0 ? '母' : '公' }}
      </template>
      <template slot="operation" slot-scope="scope, row" v-if="show_default_operation">
        <a-button type="primary" size="small" @click="openEdit('edit', row)">编辑</a-button>
      </template>
      <slot name="extra"></slot>
    </a-table>
    <add-or-edit ref="addOrEdit"></add-or-edit>
  </div>
</template>

<script>
import ItemButton from './../../components/ItemButton.vue'
import addOrEdit from './addOrEdit.vue'
export default {
  components: {
    ItemButton, addOrEdit
  },
  props: {
    page_size: {
      type: Number,
      default: 20
    },
  },
  data() {
    return {
      list: [],
      show_default_operation: true,
      pagination: {
        pageSize:20,
        current: 1,
        total: 0,
        showTotal: total => `共 ${total} 条记录`
      },
      columns: [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Vid",
          dataIndex: "vid",
          key: "vid",
          scopedSlots: {customRender: 'vid'}
        },
        {
          title: "出口号",
          dataIndex: "export_tag",
          key: "export_tag",
          scopedSlots: {customRender: 'export_tag'}
        },
        {
          title: "RF-Id",
          dataIndex: "rf_id",
          key: "rf_id",
          scopedSlots: {customRender: 'rf_id'}
        },
        {
          title: "父亲",
          dataIndex: "father",
          key: "father",
          scopedSlots: {customRender: 'father'}
        },
        {
          title: "母亲",
          dataIndex: "mother",
          key: "mother",
          scopedSlots: {customRender: 'mother'}
        },
        {
          title: "出生日期",
          dataIndex: "birth_date",
          key: "birth_date",
          scopedSlots: {customRender: 'birth_date'}
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          scopedSlots: {customRender: 'gender'}
        },
        {
          title: '操作',
          key: "operation",
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  methods: {
    open(list, pagination, show_default_operation=true, columns=this.columns) {
      this.list = list
      this.pagination = pagination
      this.pagination.pageSize = this.page_size
      this.show_default_operation = show_default_operation
      this.columns = columns
      if (!show_default_operation) {
        this.columns.pop()
      }
    },
    openEdit(action, item) {
      if (action == 'add') {
        this.$refs.addOrEdit.open('add', item)
      } else {
        this.$refs.addOrEdit.open('edit', item)
      }
    },
    handleTableChange(pagination, filters, sorter) {
      this.$emit('page-change', pagination)
    }

  }
}
</script>

<style>
</style>