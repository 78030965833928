<template>
  <a-descriptions :title="title" bordered :column="column">
    <a-descriptions-item label="VId">
      <item-button :text="item_data.vid" :id="item_data.id" v-if="show_link"></item-button>
      <span v-else>{{ item_data.vid }}</span>
    </a-descriptions-item>
    <a-descriptions-item label="性别">
      {{ item_data.gender == 0 ? "母" : "公"}}
    </a-descriptions-item>
    <a-descriptions-item label="出生日期">
      {{ item_data.birth_date }}
    </a-descriptions-item>
    <a-descriptions-item label="Rf-Id">
      {{ item_data.rf_id }}
    </a-descriptions-item>
    <a-descriptions-item label="gebv">
      {{ item_data.gebv }}
    </a-descriptions-item>
    <a-descriptions-item label="脂肪gebv">
      {{ item_data.gebv_fat }}
    </a-descriptions-item>
    <a-descriptions-item label="蛋白gebv">
      {{ item_data.gebv_protein }}
    </a-descriptions-item>
    <a-descriptions-item label="产量gebv">
      {{ item_data.gebv_volume }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import ItemButton from '../../components/ItemButton.vue';
export default {
  name: 'detailSection',
  components: {
    ItemButton
  },
  props: {
    column: {
      type: Number,
      default: 3
    },
    title: {
      type: String,
      default: "个体详细信息"
    },
    item_data: {
      type: Object,
      default: () => {}
    },
    show_link: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>