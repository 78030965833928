<template>
  <div>
    <a-row type="flex">
      <div class="duel-node"></div>
      <span>同时存在重复</span>
      <div class="male-duel-node"></div>
      <span>左表个体祖先重复</span>
      <div class="female-duel-node"></div>
      <span>右表个体祖先重复</span>
    </a-row>
    <a-row :gutter="10" type="flex" class="margin-top-10">
      <a-col :span="12" v-if="!loading">
        <item-tree-chart ref="maleChart"></item-tree-chart>
      </a-col>
      <a-col :span="12" v-if="!loading">
        <item-tree-chart ref="femaleChart"></item-tree-chart>
      </a-col>
      <a-col :span="24" v-if="loading" class="flex-center">
        <a-spin></a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import itemTreeChart from './itemTreeChart.vue';
export default {
  components: {
    itemTreeChart
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async draw(male_id, female_id) {
      this.loading = true
      const res = await this.$api.get_yu_zhong_result({
        male_id: male_id,
        female_id: female_id
      })
      this.loading = false

      this.$nextTick(() => {
          this.$refs.maleChart.draw(res.data.male_chart_data)
          this.$refs.femaleChart.draw(res.data.female_chart_data)
        })
    }
  },
}
</script>

<style scoped>
.duel-node {
  background-color: #c23531;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin-right: 10px;
}
.male-duel-node {
  background-color: #E9C874;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 10px;
}
.female-duel-node {
  background-color: #E6A23C;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 10px;
}
</style>